import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProgramService } from 'src/app/shared/services/program.service';
import { IndexComponent } from './index/index.component';
import { ProposalIndexComponent } from './proposal-index/proposal-index.component';

const routes: Routes = [
  {
    path: ':id/:vendorID',
    component: IndexComponent,data: { breadcrumb: (data: any) => `${data?.opportunity?.opportunity_number}` },
    resolve: { opportunity: ProgramService },
    children: [
      {
        path: 'proposal-details',
        component: ProposalIndexComponent,//data: { breadcrumb: "Proposal Details"},
        loadChildren: () => import('./vendor.module').then(m => m.VendorModule)
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VendorRoutingModule { }
