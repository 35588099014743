@Pipe({
  name: 'formGroupNameProposal'
})
export class FormGroupNameProposalPipe {
  transform(item: any, arr: any, category: any) {
    let formGroupNameIndex = 1000000;
    try {
      arr?.controls.forEach((res: any, index: any) => {
        if (res.controls.scopeId.value == item.id && res.controls.category.value == category) {
          formGroupNameIndex = index;;
          throw BreakException;
        }
      });
    } catch (e) {
      if (e != BreakException) {
        throw e
      }
    }
    return formGroupNameIndex;
  }
}

import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, Pipe, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GetProposalService } from 'src/app/shared/services/get-proposal.service';
import { ProgramService } from 'src/app/shared/services/program.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { I } from '@angular/cdk/keycodes';
import { EvaluationService } from 'src/app/shared/services/evaluation.service';
var _ = require('lodash');
var BreakException = {};
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import { MatTabGroup } from '@angular/material/tabs';
@Component({
  selector: 'app-proposal-index',
  templateUrl: './proposal-index.component.html',
  styleUrls: ['./proposal-index.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ProposalIndexComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  @Input() opportunityId: any;
  truncateShow = false;
  @Input() vendorID: any;
  @Input() receivedDate: any;
  @Input() vendorStatus: any
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  totalFiles: any;
  vendorList: any;
  customStyle = {
    backgroundColor: "#f1f1fc",
    borderRadius: "50%",
    color: "#232459",
    cursor: "pointer",
    fontWeight: 'bolder',
  };
  scopeList: any[] = [];
  scopeCategory: any[] = [];
  groupScope: any[] = [];
  matrixForm: any;
  evaluationForm: FormGroup | any;
  criticalityList: any[] = [];
  items: any;
  scoreRange: any;
  public maxValue: any;
  public minValue: any;
  groupScope1: any[] = [];
  scopeObj: any;
  itemId: any;
  auditLists: any;
  evaRes: any;
  showTable = false;
  showCard = true;
  hideCard = false;
  startRange = 0;
  endRange = 10;
  score_display_name = 'SCORE OUT OF 10';
  pmRes: any;
  moreHide = false;
  id: any;
  data: any;
  score_Range: any;
  @Output() onClose = new EventEmitter();
  disableFields: boolean = false;
  canEdit = false;
  showVendorSave: boolean = false;
  showEvaluatorSave: boolean = false;
  evaluationTermMatrix: FormArray | any;
  evaluationScopeMatrix: FormArray | any;
  proposal_Id: any;
  user: any;
  responseSubmitted: boolean = false;
  responseToeSubmitted: boolean = false;
  accessor_name: string = "";
  showProposalView: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private proposalService: GetProposalService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private programService: ProgramService,
    private formBuilder: FormBuilder,
    public permissionService: NgxPermissionsService,
    public dialog: MatDialog,
    private evaluationService: EvaluationService,
    private changeDetectorRef: ChangeDetectorRef

  ) {
    this.evaluationTermMatrix = this.formBuilder.array([])
    this.evaluationScopeMatrix = this.formBuilder.array([])
  }

  async ngOnInit() {
    const perm = JSON.parse(localStorage.getItem('permission') || '{}');
    this.user = JSON.parse(localStorage.getItem('permission') || '{}');

    this.permissionService.loadPermissions(perm.permissions);
    this.canEdit = this.user?.is_vendor;

    // this.route?.parent?.params.subscribe((res: any) => {
    //   this.opportunityId = atob(res.id);
    //   this.vendorID = res.vendorID;
    this.getVendorList();
    this.createForm();
    this.getScoreRange();
    this.getFile();
    this.getSettings();
    this.getOpportunityTermsMatrix();

    if (this.user?.is_vendor) {
      this.showVendorSave = true;
    };
    if (!this.user?.is_vendor) {
      this.showEvaluatorSave = true;
    };


    if (!this.user?.is_vendor) {
      this.getEvaluatorResponse();
    };
    if (this.user?.is_vendor) {
      this.getPMResponse();
    };
  }

  createForm() {
    this.matrixForm = new FormGroup({
      items: new FormArray([])
    });
  }

  createItem(data: any): FormGroup {
    return this.formBuilder.group({
      response: [data?.response || '', Validators.required],
      coverage: [data?.coverage || '', Validators.required],
      refs: [data?.refs],
      score: [data?.score || ''],
      notes: [data?.notes || ''],
      scopeId: [data?.scopeId || ''],
      category: [data?.category || '']
    });
  }

  matrixValueFormArray() {
    return this.matrixForm.get('items') as FormArray;
  }

  getVendorList() {
    this.proposalService.getProposalOverview(this.opportunityId).subscribe((res: any) => {
      const vendorList = res.records.filter((i: any) => i.vendor == this.vendorID);
      const proposal_Id = res.opportunity_data.opportunity_vendors.filter((i: any) => i.vendor_details.id == this.vendorID);
      this.vendorList = vendorList[0];
      this.proposal_Id = proposal_Id[0].proposal_id;
    }, error => {
      throw error;
    })
  }

  viewEdit() {
    this.router.navigate([`getproposal/${btoa(this.opportunityId)}/proposal-evaluation`]);
  }

  getOpportunityById() {
    this.programService.getOpportunityById(this.opportunityId).subscribe((res: any) => {
      this.pmRes = res.opportunity_scope;
      this.items = this.matrixForm.get('items') as FormArray;
      this.pmRes?.forEach((c: any, i: any) => {
        this.addCategoryScope(c?.category);
        c.category?.scope.forEach((s: any) => {
          this.addCategoryScopeResponse(s, i, c?.category);
          const vendor_response = {
            response: '',
            coverage: '',
            refs: '',
            score: '',
            notes: '',
            scopeId: s?.id,
            category: c?.category?.id
          }
          s.vendor_response = vendor_response;
          // this.items.push(this.createItem(s?.vendor_response));
          // console.log(this.items);
        });
      });
      if (this.vendorStatus == 'SoW Signed' || this.vendorStatus == 'Rejected' || res.status.slug == 'opportunity_cancelled') {
        this.disableFields = true;
        this.matrixForm.get("items").controls.forEach((control: any, index: number) => {
          this.matrixForm.get("items").controls[index].disable();
        });
      } else {
        this.disableFields = false;
        this.matrixForm.get("items").controls.forEach((control: any, index: number) => {
          this.matrixForm.get("items").controls[index].enable();
        });
      }
    })
    this.changeDetectorRef.detectChanges();
  }
  clickPrevious() {
    this.onClose.emit('');
    //this.router.navigate([`getproposal/${btoa(this.opportunityId)}/proposal-evaluation`]);

  }

  onFileSelected(e: any) {
    const file = e;
    const fd: any = new FormData();
    fd.append('file_name', file.target.files[0].name);
    fd.append('file', file.target.files[0]);
    fd.append('vendor_id', this.vendorID);
    if (file.target.files[0].size > 5000000) {
      this.toastrService.error('Please upload less than 5MB');
      return;
    }
    this.spinner.show();
    this.data = fd
    this.proposalService.uploadDocument(this.opportunityId, this.data).subscribe((res: any) => {
      this.toastrService.success('File uploaded successfully');
      this.spinner.hide();
      this.getFile();
    }, error => {
      throw error;
    })
  }

  getFile() {
    this.proposalService.getDocument(this.opportunityId, this.vendorID).subscribe((res: any) => {
      this.totalFiles = res;
      this.totalFiles.map((i: any) => {
        i.size = i.size / 1000000;
        const ext = i.file.substring(i.file.lastIndexOf('.') + 1)
        i.doc = ext?.toLowerCase()
      });
    }, error => {
      throw error;
    })
  }

  downloadFile(e: any) {
    var payload = {
      file_name: e.file,
      vendor_id: this.vendorID
    }
    this.spinner.show();
    this.proposalService.downloadDocument(this.opportunityId, payload).subscribe((res: any) => {
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(res);
      downloadLink.setAttribute('download', e.file);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.toastrService.success('File downloaded successfully');
      this.spinner.hide();
    }, error => {
      throw error;
    })
  }

  deleteFile(i: any, content: any) {
    this.itemId = i;
    this.dialog.open(content)
  }

  deleteDialog() {
    var payload = {
      file_name: this.itemId.file,
      vendor_id: this.vendorID
    }
    this.spinner.show();
    this.proposalService.deleteFile(this.opportunityId, payload).subscribe((res: any) => {
      this.toastrService.success('File deleted successfully');
      this.dialog.closeAll();
      this.spinner.hide();
      this.getFile();
    }, error => {
      throw error;
    })
  }

  viewList() {
    this.router.navigate([`getproposal/${btoa(this.opportunityId)}/proposal-evaluation`])
  }

  updatePMResponse(is_submitted: any) {
    this.spinner.show();
    let matrixForm = this.evaluationScopeMatrix.value;
    const matrix = matrixForm.some((m: any) => (m.coverage < 0) || (m.coverage > 100));
    if (matrix == true) {
      this.toastrService.error('Please enter the coverage values within 1 to 100');
      this.spinner.hide();
      return;
    }

    if (this.matrixForm.invalid && is_submitted) {
      this.toastrService.error('Please fill all the fields');
      this.spinner.hide();
      return;
    }

    const payload: any = {
      vendor: this.vendorID,
      category: []
    };

    (matrixForm || []).forEach((si: any) => {
      let scope_category: any = {
        scope_category: si.cat_id,
        scope: []
      };
      si.scope.forEach((element: any) => {
        const scope: any = {
          uuid: element.uuid,
          response: element.vendorResponse,
          coverage: element.coverage,
          refs: element.refs,
          notes: element.notes,
          score: element.score
        };
        scope_category.scope.push(scope);
      });
      payload.category.push(scope_category);
    });

    payload['is_submitted'] = is_submitted; 
    this.proposalService.updatePMResponse(this.opportunityId, payload).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {

        this.programService.getOppData(this.opportunityId).subscribe((res: any) => {
          this.programService.setStatusValue(res?.status);
          //this.router.navigate([`getproposal/${btoa(this.opportunityId)}/summary`]);
        });
        this.toastrService.success('Saved Successfully');
        this.onClose.emit('next');
      }
    }, error => {
      this.toastrService.error(error?.error)
      this.spinner.hide();
      throw error;
    })

  }

  getPMResponse() {
    this.spinner.show();
    this.proposalService.getPMResponse(this.opportunityId, this.vendorID).subscribe((res: any) => {
      this.responseSubmitted = res.is_submitted;
      this.pmRes = res.response_data;
      this.auditLists = res.audit_log;
      this.auditLists.map((i: any) => {
        i.last_modified_on = !!i.last_modified_on ? moment(i.last_modified_on).format('DD-MM-yyyy  HH:mm') : '';
      })

      if (this.pmRes.length == 0) {
        this.getOpportunityById();
      }
      else {
        this.items = this.matrixForm.get('items') as FormArray;
        this.pmRes.forEach((c: any, i: any) => {
          this.addCategoryScope(c?.category);
          c.category.scope.forEach((s: any) => {
            this.addCategoryScopeResponse(s, i, c?.category);
            s.vendor_response.scopeId = s.id;
            s.vendor_response['category'] = c.category?.id;
            s.vendor_response.truncateShow = true;
            this.items.push(this.createItem(s.vendor_response));

          });
        });
        this.programService.getOpportunityById(this.opportunityId).subscribe((res: any) => {
          if (this.vendorStatus == 'SoW Signed' || this.vendorStatus == 'Rejected' || res.status.slug == 'opportunity_cancelled') {
            this.disableFields = true;
            this.matrixForm.get("items").controls.forEach((control: any, index: number) => {
              this.matrixForm.get("items").controls[index].disable();
            });
          } else {
            this.disableFields = false;
            this.matrixForm.get("items").controls.forEach((control: any, index: number) => {
              this.matrixForm.get("items").controls[index].enable();
            });
          }
        })
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  getNotes(scopeId: any) {
    const evaNotes = this.scopeCategory.find((i: any) => i.scopeId == scopeId);
    return evaNotes.evaluator || [];
  }

  getScoreInTable(scopeId: any) {
    const evaScore = this.scopeCategory.find((i: any) => i.scopeId == scopeId);
    return evaScore.evaluator || [];
  }


  getScoreRange() {
    this.proposalService.getScoreRange(this.opportunityId).subscribe((res: any) => {
      this.scoreRange = res.score_range_details;
      this.startRange = this.scoreRange?.start_range;
      this.endRange = this.scoreRange?.end_range;
      this.score_display_name = this.scoreRange?.display_name;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  getEvaluatorResponse() {
    this.spinner.show();
    this.proposalService.getEvaluatorResponse(this.opportunityId, this.vendorID).subscribe((res: any) => {
      this.responseSubmitted = res.is_submitted;
      this.accessor_name = res.accessor_name;
      this.pmRes = res.response_data;
      this.auditLists = res.audit_log;
      this.auditLists.map((i: any) => {
        i.last_modified_on = !!i.last_modified_on ? moment(i.last_modified_on).format('DD-MM-yyyy  HH:mm') : '';
      })

      this.items = this.matrixForm.get('items') as FormArray;
      this.pmRes.forEach((c: any, i: any) => {
        this.addCategoryScope(c?.category);
        c.category.scope.forEach((s: any) => {
          this.addCategoryScopeResponse(s, i, c?.category);
          s.vendor_response.scopeId = s.id;
          s.vendor_response['category'] = c.category?.id;
          s.vendor_response.truncateShow = true;
          this.items.push(this.createItem(s.vendor_response));

        });
      });
      this.programService.getOpportunityById(this.opportunityId).subscribe((res: any) => {
        if (this.vendorStatus == 'SoW Signed' || this.vendorStatus == 'Rejected' || res.status.slug == 'opportunity_cancelled') {
          this.disableFields = true;
          this.matrixForm.get("items").controls.forEach((control: any, index: number) => {
            this.matrixForm.get("items").controls[index].disable();
          });
        } else {
          this.disableFields = false;
          this.matrixForm.get("items").controls.forEach((control: any, index: number) => {
            this.matrixForm.get("items").controls[index].enable();
          });
        }
      })




      
      // this.items = this.matrixForm.get('items') as FormArray;
      // (this.pmRes || []).forEach((c: any) => {
      //   c.category.scope.forEach((s: any) => {
      //     if (s.evaluator_response.length > 0) {
      //       s.vendor_response.score = s.evaluator_response[0].score;
      //       s.vendor_response.notes = s.evaluator_response[0].notes;
      //     } else {
      //       s.vendor_response.score = '';
      //       s.vendor_response.notes = '';
      //     }
      //     s.vendor_response.scopeId = s.id;
      //     s.vendor_response['category'] = c.category?.id;
      //     this.items.push(this.createItem(s.vendor_response));
      //   });
      // });
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  getSettings() {
    this.evaluationService.getSettings(this.opportunityId).subscribe((res: any) => {
      this.score_Range = res?.score_range_details?.end_range;
    }, error => {
      throw error;
    })
  }

  updateEvaluatorResponse() {
    this.spinner.show();
    this.score_Range = this.score_Range ? this.score_Range : 10;
    let matrixForm = this.evaluationScopeMatrix.value;
    const matrix = matrixForm.some((m: any) => (m.score < this.startRange) || (m.score > this.endRange));
    if (matrix == true) {
      this.toastrService.error(`Please enter the score values within ${this.score_Range}`);
      this.spinner.hide();
      return;
    }

    if (this.matrixForm.invalid) {
      this.toastrService.error('Please fill all the fields');
      this.spinner.hide();
      return;
    }

    const payload: any = {
      vendor: this.vendorID,
      category: []
    };
    
    (matrixForm || []).forEach((si: any) => {
      let scope_category: any = {
        scope_category: si.cat_id,
        scope: []
      };
      si.scope.forEach((element: any) => {
        const scope: any = {
          uuid: element.uuid,
          response: element.vendorResponse,
          coverage: element.coverage,
          refs: element.refs,
          notes: element.notes,
          score: element.score
        };
        scope_category.scope.push(scope);
      });
      payload.category.push(scope_category);
    });
    payload["is_submitted"] = true;
    this.proposalService.updatePMResponse(this.opportunityId, payload).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {

        this.programService.getOppData(this.opportunityId).subscribe((res: any) => {
          this.programService.setStatusValue(res?.status);
          //this.router.navigate([`getproposal/${btoa(this.opportunityId)}/summary`]);
        });
        this.toastrService.success('Saved Successfully');

        this.tabGroup.selectedIndex = 1;
        // this.onClose.emit('next');
      }
    }, error => {
      this.toastrService.error(error?.error)
      this.spinner.hide();
      throw error;
    })


    
    // let matrixForm = this.evaluationScopeMatrix.controls.items.value;
    // const evmatrix = matrixForm.some((m: any) => (m.score < this.startRange) || (m.score > this.endRange));
    // if (!this.receivedDate) {
    //   this.toastrService.error('Please select Received date');
    //   this.spinner.hide();
    //   return;
    // }
    // if (evmatrix == true) {
    //   this.toastrService.error(`Please enter the score values within ${this.score_Range}`);
    //   this.spinner.hide();
    //   return;
    // }

    // if (this.matrixForm.invalid) {
    //   this.toastrService.error('Please fill all the fields');
    //   this.spinner.hide();
    //   return;
    // }

    // const scoreMat = matrixForm.some((m: any) => (isNullOrUndefined(m.score)));
    // if (scoreMat == true) {
    //   this.toastrService.error('Please enter the score');
    //   return;
    // }

    // let evaluator_response: any = [];
    // (matrixForm || []).forEach((item: any) => {
    //   (this.pmRes || []).forEach((s: any) => {
    //     s.category.scope.forEach((e: any) => {
    //       if (e.id == item.scopeId && s.category.id === item.category) {
    //         evaluator_response.push({
    //           score: item.score,
    //           notes: item.notes,
    //           vendor_response_id: e.vendor_response.id ? e.vendor_response.id : this.vendorID
    //         })
    //       }
    //     });
    //   })
    // })

    // let payload: any = {
    //   evaluator_response: evaluator_response,
    // }
    // //payload['received_date']= this.receivedDate;
    // this.spinner.show();
    // this.proposalService.updateEvaluatorResponse(this.opportunityId, payload).subscribe((res: any) => {
    //   //this.router.navigate([`getproposal/${btoa(this.opportunityId)}/proposal-evaluation`]);
    //   this.spinner.hide();
    //   if (res) {
    //     // this.programService.getOpportunityById(this.opportunityId).subscribe((response: any) => {
    //     //   this.programService.setStatusValue(response?.status);
    //     // });        
    //     this.programService.getOppData(this.opportunityId).subscribe((res: any) => {
    //       this.programService.setStatusValue(res?.status);
    //       //this.router.navigate([`getproposal/${btoa(this.opportunityId)}/summary`]);
    //     });
    //     this.toastrService.success('Saved Successfully');
    //     this.onClose.emit('next');

    //   }
    // }, error => {
    //   this.toastrService.error('Please enter the required value');
    //   this.spinner.hide();
    //   throw error;
    // })
  }
  closeDialog() {
    this.dialog.closeAll();
  }

  clickHide() {
    this.showTable = false;
    this.showCard = true;
    this.hideCard = !this.hideCard;
  }

  clickShow() {
    this.showTable = !this.showTable;
    this.hideCard = true;
    this.showCard = !this.showCard;

  }

  showTruncate(i: any) {
    this.id = i;
    this.truncateShow = true;

    this.moreHide = true;
  }


  getName(name: any) {
    return name?.split(' ').slice(0, 2).map((n: any) => n[0]).join('');
  }


  getOpportunityTermsMatrix() {
    this.proposalService.getOpportunityTermsDetailsResponse(this.opportunityId, this.vendorID).subscribe((resp: any) => {
      this.responseToeSubmitted = resp.is_submitted;
      let response = resp.records;
      response.forEach((cat: any, i: any) => {
        this.addCategories(cat);
        cat.opportunityterms.forEach((terms: any) => {
          this.addCatTerms(i, terms, cat)
        });
      });
    });

    // this.addCategories('')
  }
  addCategories(cat: any) {
    var data = {
      cat_id: cat?.id,
      cat_name: cat?.name,
      terms: []
    }
    this.evaluationTermMatrix.push(
      this.formBuilder.control(data));
  }

  addCatTerms(catIndex: number, term: any, cat: any) {
    let index = this.evaluationTermMatrix.value.findIndex((x: any) => x.cat_id == cat.id);
    this.evaluationTermMatrix.controls[index].value.terms.push({
      proposal: 30,
      term_id: term.term.id ? term.term.id : null,
      opp_term_id: term.id ? term.id : null,
      term_name: term.term.title,
      term_comment: term.comments,
      criticality: term?.evals[0]?.criticality?.title ? term?.evals[0]?.criticality?.title : '',
      weightage: term?.evals[0]?.weightage ? term?.evals[0]?.weightage : '',
      vendorResponse: term?.offers[0]?.response ? term?.offers[0]?.response : '',
      coverage: term?.offers[0]?.coverage ? term?.offers[0]?.coverage : '',
      refs: term?.offers[0]?.refs ? term?.offers[0]?.refs : '',
      score: term?.offers[0]?.evals[0]?.score ? term?.offers[0]?.evals[0]?.score : '',
      notes: term?.offers[0]?.evals[0]?.notes ? term?.offers[0]?.evals[0]?.notes : '',
      evals: term?.offers[0]?.evals ? term?.offers[0]?.evals : [],
      id: term?.offers[0]?.id ? term?.offers[0]?.id : null,
    })
  }

  updateTermsEvaluationVendorComment(is_submitted: any) {
    var data: any = [];
    this.evaluationTermMatrix?.value.forEach((cat: any) => {
      cat.terms.forEach((term: any) => {
        data.push({
          proposal: this.proposal_Id ? this.proposal_Id : null,
          term: term?.term_id,
          response: term?.vendorResponse,
          coverage: term?.coverage,
          refs: term?.refs,
          evals: [{ score: term?.score, notes: term?.notes }],
          id: term?.id
        })

      });
    });
    this.proposalService.saveProposalEvaluationTerms({ termoffers: data, is_submitted: is_submitted }).subscribe((resp: any) => {
      this.toastrService.success('Saved Successfully');
      this.onClose.emit('next');
    });
  }

  addCategoryScope(scopeCat: any) {
    var data = {
      cat_id: scopeCat?.id,
      cat_name: scopeCat?.name,
      scope: []
    }
    this.evaluationScopeMatrix.push(
      this.formBuilder.control(data));
  }
  addCategoryScopeResponse(scope: any, index: any, cat: any) {
    let index_num = this.evaluationScopeMatrix.value.findIndex((x: any) => x.cat_id == cat.id);

    this.evaluationScopeMatrix.controls[index_num].value.scope.push({
      title: scope?.title,
      description: scope?.description,
      criticality: scope?.criticality[0]?.title,
      weightage: scope?.criticality[0]?.weightage,
      vendorResponse: scope?.vendor_response?.response,
      coverage: scope?.vendor_response?.coverage,
      refs: scope?.vendor_response?.refs,
      score: !this.user?.is_vendor ? scope?.evaluator_response[0]?.score : '',
      notes: !this.user?.is_vendor ? scope?.evaluator_response[0]?.notes : '',
      evaluator_response: scope?.evaluator_response,
      vendorResposeId: scope?.vendor_response?.id,
      scopeId: scope?.id,
      uuid: scope?.uuid,
    })
  }

  checkVal(catind: any, scopeind: any, score: any){
    if(score < 0){
      this.evaluationScopeMatrix.controls[catind].value.scope[scopeind].score = 0;
    }else if(score > 100){
      this.evaluationScopeMatrix.controls[catind].value.scope[scopeind].score = 100;
    }
  }
  
  checkValTerm(catind: any, termind: any, score: any){
    if(score < 0){
      this.evaluationTermMatrix.controls[catind].value.terms[termind].score = 0;
    }else if(score > 100){
      this.evaluationTermMatrix.controls[catind].value.terms[termind].score = 100;
    }
  }
}


