<div class="d-flex m-0 proposal-details p-0">
    <div class="col-md-12 p-0 section-1 mr-0">
        <div class="custom-height" id="custom-height">
            <!-- <div class="d-flex m-b-10">
                <app-bread-crumb></app-bread-crumb>
            </div> -->
            <div class="row ">
                <div class="col-md-12">
                    <div class="d-flex justify-content-between align-items-center cursor-pointer"
                        (click)="clickPrevious()">
                        <!-- <div class="d-flex">

                            <div class="mt-2 px-2 cursor-pointer fw-bold"><i class="far fa-chevron-left fw-bold"></i>
                            </div>
                            <div class="mr-2">
                                <span class="pt-3 name position-relative">


                                    {{getName(vendorList?.vendor_details?.name)}} </span>
                            </div>
                            <div class="position: absolute toe-response">
                                <div class="d-flex ">
                                    <p class="toe-vendor-name">
                                        {{vendorList?.vendor_details?.name}}</p>
                                </div>
                                <br>
                                <div class="d-flex">
                                    <p class="  text-muted toe-vendor-team-name">
                                        {{vendorList?.vendor_details?.team}}
                                    </p>
                                </div>
                            </div>
                        </div> -->

                        <!--  <div class="d-flex mb-4">
                            <button type="button" class="btn btn-light save-btn" (click)="updatePMResponse()"
                                *ngxPermissionsOnly="['proposal.add_vendorresponse']">Save
                            </button>
                            <button type="button" class="btn btn-light save-btn" (click)="updateEvaluatorResponse()"
                                *ngxPermissionsOnly="['proposal.add_evaluatorresponse']">Save
                            </button>
                        </div> -->
                    </div>
                </div>
            </div>
            <!-- <hr class="col-md-12 px-0"> -->
            <mat-tab-group mat-align-tabs="start" class=" head" #tabGroup>
                <mat-tab label="Scope Evaluation">
                    <div class="scroll-screen" *ngIf="!user?.is_vendor ? responseSubmitted : true">
                        <div class="custom-table">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th class="text-center">
                                            {{staticText?.toe_response.proposal_doc | titlecase}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p class="doc-subhead mb-1">{{staticText?.toe_response.content1}}</p>
                                            <div *ngIf="totalFiles?.length == 0">
                                                <div class="row" *ngIf="!disableFields">
                                                    <div class="col-md-4 ">
                                                        <div class="p-2 mt-1 upload" *ngIf="user?.is_vendor">
                                                            <div class="w-100 cursor-pointer d-flex justify-content-center"
                                                                (click)="fileInput.click()">
                                                                <span class="mt-1 ms-2 my-auto secondary">
                                                                    {{staticText?.common.upload_doc}}</span>
                                                            </div>
                                                            <input hidden #fileInput type="file" id="file"
                                                                (change)="onFileSelected($event)">
                                                        </div>
                                                    </div>
                                                    <p class="text-dark file-status fs-14 mb-0">
                                                        {{staticText?.common.doc_ext_maxsize}}</p>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="totalFiles?.length>0">
                                                <div class="row" *ngFor="let i of totalFiles">
                                                    <div class="d-flex align-items-center">
                                                        <div class="d-flex">
                                                            <div class="mr-2 mt-2">
                                                                <img *ngIf="i.doc == 'doc' || i.doc == 'docx'"
                                                                    src="./assets/images/word.svg" height="40">
                                                                <img *ngIf="i.doc == 'pdf'"
                                                                    src="./assets/images/pdf.svg" height="40">
                                                                <img *ngIf="i.doc == 'xls' || i.doc == 'xlsx'"
                                                                    src="./assets/images/excel.svg" height="40">
                                                                <img *ngIf="i.doc == 'csv'"
                                                                    src="./assets/images/csv.png" height="40">
                                                                <mat-icon
                                                                    *ngIf="i.doc !== 'pdf' && i.doc !== 'doc' && i.doc !== 'docx' && i.doc !== 'xls' && i.doc !== 'xlsx' && i.doc !== 'csv'"
                                                                    mat-list-icon
                                                                    class="material-icons my-auto fs-1 text-danger">
                                                                    description</mat-icon>
                                                            </div>
                                                            <div class="m-2 ml-2">
                                                                {{i.file}}
                                                                <p class="text-muted doc-subhead mb-0">{{i.size | number
                                                                    : '1.2-2' }}MB</p>
                                                            </div>

                                                        </div>
                                                        <div class="d-flex">
                                                            <div>
                                                                <img (click)="downloadFile(i)"
                                                                    class="cursor-pointer px-3"
                                                                    src="./assets/images/download-icon-new.svg">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row" *ngIf="totalFiles?.length == 0">
                                                <div *ngIf="user?.is_vendor">
                                                    <div class="col-md-9 d-flex p-0" *ngIf="totalFiles?.length == 0">
                                                        <p class="px-2">{{staticText?.common.no_files_found}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row">
                            <div class="scroll-list">
                                <!-- <div class="col-md-12 p-0"> -->
                                <div>
                                    <div class="custom-table" [formGroup]="evaluationScopeMatrix">
                                        <table class="table table-bordered">
                                            <thead>
                                                <ng-container
                                                    *ngFor="let cat of evaluationScopeMatrix.controls; let catIndex=index">

                                                    <tr>
                                                        <th class="text-center"> {{cat?.value?.cat_name}}</th>
                                                    </tr>
                                                    <ng-container
                                                        *ngFor="let scope of cat?.value?.scope; let scopeIndex=index">
                                                        <tr>
                                                            <th>
                                                                {{scope?.title}}<br>
                                                                {{scope?.description}}
                                                                <div class="d-flex justify-content-start">
                                                                    <p class="weight-design fw-light py-1 mb-0"><span
                                                                            class="py-2 ps-1">{{scope?.weightage}}%</span>
                                                                    </p>
                                                                    <p
                                                                        class="background-design text-uppercase mx-3 text-center py-1 mb-0">
                                                                        <span class="ps-2">{{scope?.criticality}}</span>
                                                                    </p>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div class="row w-100" *ngIf="!user?.is_vendor">
                                                                            <div class="col-sm-12 mb-2">
                                                                                <p class="m-0" style="font-size: 12px;">
                                                                                    {{staticText?.vendor.vendor_res}}
                                                                                </p>
                                                                                <p class="m-0"
                                                                                    style="font-size: 14px; font-weight: 500">
                                                                                    {{scope.vendorResponse}}</p>
                                                                            </div>
                                                                            <div class="col-sm-3 mb-2">
                                                                                <p class="mb-0"
                                                                                    style="font-size: 12px;">
                                                                                    {{staticText?.vendor?.deg_coverage}}
                                                                                </p>
                                                                                <p class="m-0"
                                                                                    style="font-size: 14px; font-weight: 500">
                                                                                    {{scope.coverage}}</p>
                                                                            </div>
                                                                            <div class="col-sm-9 mb-2">
                                                                                <p class="mb-0"
                                                                                    style="font-size: 12px;">
                                                                                    {{staticText?.vendor?.refs}}</p>
                                                                                <p class="m-0"
                                                                                    style="font-size: 14px; font-weight: 500; word-break: break-all;">
                                                                                    {{scope.refs}}</p>
                                                                            </div>
                                                                        </div>
                                                                        <hr *ngIf="!user?.is_vendor" class="mt-2 mb-2">

                                                                        <div
                                                                            class="col-md-12 pl-4 pr-0 field-adjust d-flex justify-content-between align-items-center">

                                                                            <div class="row w-100">
                                                                                <div class="col-md-5  p-0 align-items-center"
                                                                                    *ngIf="user?.is_vendor">
                                                                                    <mat-form-field
                                                                                        class="w-100 textarea-input"
                                                                                        appearance="outline">
                                                                                        <mat-label>{{staticText?.vendor?.vendor_res}}</mat-label>
                                                                                        <input type="text" matInput
                                                                                            status=" basic"
                                                                                            [(ngModel)]="scope.vendorResponse"
                                                                                            [ngModelOptions]="{standalone: true}"
                                                                                            name="vendorResponse"
                                                                                            [readonly]="!canEdit">
                                                                                    </mat-form-field>

                                                                                </div>
                                                                                <div class="col-md-3 d-flex align-items-center"
                                                                                    *ngIf="user?.is_vendor">

                                                                                    <mat-form-field
                                                                                        class="w-100 textarea-input "
                                                                                        appearance="outline">
                                                                                        <mat-label>{{staticText?.vendor?.deg_coverage}}</mat-label>
                                                                                        <mat-select
                                                                                            [(ngModel)]="scope.coverage"
                                                                                            [ngModelOptions]="{standalone: true}"
                                                                                            name="coverage"
                                                                                            [readonly]="!canEdit">
                                                                                            <mat-option>Select</mat-option>
                                                                                            <mat-option
                                                                                                value="Fully Addressed">Fully
                                                                                                Addressed</mat-option>
                                                                                            <mat-option
                                                                                                value="Partially Addressed">Partially
                                                                                                Addressed</mat-option>
                                                                                            <mat-option
                                                                                                value="Not Addressed">Not
                                                                                                Addressed</mat-option>
                                                                                        </mat-select>
                                                                                    </mat-form-field>

                                                                                </div>
                                                                                <div class="col-md-4 d-flex align-items-center"
                                                                                    *ngIf="user?.is_vendor">

                                                                                    <mat-form-field
                                                                                        class="w-100 textarea-input"
                                                                                        appearance="outline">
                                                                                        <mat-label>{{staticText?.vendor?.refs}}</mat-label>
                                                                                        <input type="text" matInput
                                                                                            status=" basic"
                                                                                            [(ngModel)]="scope.refs"
                                                                                            [ngModelOptions]="{standalone: true}"
                                                                                            name="refs"
                                                                                            [readonly]="!canEdit">
                                                                                    </mat-form-field>

                                                                                </div>
                                                                                <div class="col-md-2 score-width p-0 d-flex align-items-center"
                                                                                    *ngIf="user?.permissions?.indexOf('opportunity.add_opportunity') < 0 && !user?.is_vendor">
                                                                                    <mat-form-field
                                                                                        class=" w-100 textarea-input  mat-size"
                                                                                        id="setPadding"
                                                                                        appearance="outline">
                                                                                        <mat-label>{{staticText?.vendor?.score}}</mat-label>
                                                                                        <input type="number" matInput
                                                                                            fullWidth status="basic"
                                                                                            class="input-style"
                                                                                            [(ngModel)]="scope.score"
                                                                                            (blur)="checkVal(catIndex, scopeIndex, scope.score)"
                                                                                            [ngModelOptions]="{standalone: true}"
                                                                                            name="score"
                                                                                            [ngClass]="{'green-cl': scope?.score > 6 , 'orange': scope?.score <5 }">
                                                                                    </mat-form-field>
                                                                                </div>
                                                                                <div class="col-md-10  d-flex align-items-center"
                                                                                    *ngIf="user?.permissions?.indexOf('opportunity.add_opportunity') < 0 && !user?.is_vendor">
                                                                                    <mat-form-field
                                                                                        class=" w-100 textarea-input  mat-size"
                                                                                        id="setPadding"
                                                                                        appearance="outline">
                                                                                        <mat-label>{{staticText?.toe_response?.notes}}</mat-label>
                                                                                        <input type="text"
                                                                                            cdkTextareaAutosize
                                                                                            cdkAutosizeMinRows=""
                                                                                            class="" matInput fullWidth
                                                                                            status="basic"
                                                                                            [(ngModel)]="scope.notes"
                                                                                            [ngModelOptions]="{standalone: true}"
                                                                                            name="notes"
                                                                                            placeholder="Assessor Notes">
                                                                                    </mat-form-field>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div *ngIf="user?.permissions?.indexOf('opportunity.add_opportunity') > -1 && !user?.is_vendor"
                                                                            style="border: 1px solid #cfc2c2; border-radius: 7px;">
                                                                            <div style="background-color: #d5d0d0; border-radius: 5px;"
                                                                                class="mb-1 p-2">
                                                                                <div class="row">
                                                                                    <div class="col-sm-3">
                                                                                        <p class="m-0"
                                                                                            style="font-size: 12px;">
                                                                                            Assessor Name </p>
                                                                                    </div>
                                                                                    <div class="col-sm-7">
                                                                                        <p class="m-0"
                                                                                            style="font-size: 12px;">
                                                                                            Assessor Notes </p>
                                                                                    </div>
                                                                                    <div class="col-sm-2">
                                                                                        <p class="m-0 text-center"
                                                                                            style="font-size: 12px;">
                                                                                            Score </p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div *ngFor="let h of scope.evaluator_response"
                                                                                style=" border-radius: 5px; border-bottom: 1px solid #ded2d2;"
                                                                                class="p-2">
                                                                                <div class="row">
                                                                                    <div class="col-sm-3">
                                                                                        <p class="m-0"
                                                                                            style="font-size: 12px;">
                                                                                            {{h.created_by__first_name
                                                                                            }} {{h.created_by__last_name
                                                                                            }}</p>
                                                                                    </div>
                                                                                    <div class="col-sm-7">
                                                                                        <p class="m-0"
                                                                                            style="font-size: 12px;">
                                                                                            {{h.notes }} </p>
                                                                                    </div>
                                                                                    <div class="col-sm-2">
                                                                                        <p class="m-0 text-center"
                                                                                            style="font-size: 12px;">
                                                                                            {{h.score }} </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                </ng-container>

                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!user?.is_vendor ? !responseSubmitted : false">
                        <h6 class="my-4 text-center">Awaiting vendor response</h6>
                    </div>
                    <div class="d-flex justify-content-end mt-2 p-2 pt-2 footer-style"
                        *ngIf="(!user?.is_vendor ? responseSubmitted : user?.is_vendor && responseSubmitted ? false : true) && user?.permissions?.indexOf('opportunity.add_opportunity') < 0">
                        <div>
                            <button type="button" mat-button color="primary"
                                (click)="clickPrevious()">{{staticText?.buttons.cancel_btn}}
                            </button>
                        </div>
                        <div *ngIf="!disableFields">
                            <button type="button" *ngIf="user?.is_vendor" mat-button mat-stroked-button class="mx-1"
                                style="width: auto" (click)="updatePMResponse(false)">{{staticText?.buttons.save_draft}}
                            </button>
                            <button type="button" *ngIf="user?.is_vendor" mat-button mat-flat-button class="bg-primary text-white mx-1"
                                (click)="updatePMResponse(true)">{{staticText?.buttons.submit_draft}}</button>

                            <button type="button" *ngIf="!user?.is_vendor" mat-button mat-flat-button class="bg-primary text-white mx-1"
                                (click)="updateEvaluatorResponse()">{{staticText?.buttons.save_btn}}</button>
                        </div>
                    </div>
                </mat-tab>

                <!---Terms Of Engagement Evaluation-->
                <mat-tab label="Terms of Engagement Evaluation">
                    <div class="scroll-screen" *ngIf="!user?.is_vendor ? responseToeSubmitted : true">
                        <div class="custom-table">
                            <div class="row">
                                <div class="scroll-list" [formGroup]="evaluationTermMatrix">
                                    <div *ngFor="let cat of evaluationTermMatrix.controls; let catIndex=index">
                                        <!-- <div class="col-md-12 p-0"> -->
                                        <div class="custom-table">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center"> {{cat?.value?.cat_name}}</th>
                                                    </tr>
                                                    <ng-container
                                                        *ngFor="let term of cat?.value?.terms; let termIndex=index">
                                                        <tr>
                                                            <th>
                                                                {{term?.term_name}}<br>
                                                                <span *ngIf="term.term_comment"
                                                                    style="color:rgb(188, 101, 101)">[ask:{{term.term_comment}}]</span>
                                                                <div class="d-flex justify-content-start">
                                                                    <p class="weight-design fw-light py-1 mb-0"><span
                                                                            class="py-2 ps-1">{{term?.weightage}}%</span>
                                                                    </p>
                                                                    <p
                                                                        class="background-design text-uppercase mx-3 text-center py-1 mb-0">
                                                                        <span class="ps-2">{{term?.criticality}}</span>
                                                                    </p>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="row w-100" *ngIf="!user?.is_vendor">
                                                                    <div class="col-sm-12 mb-2">
                                                                        <p class="m-0" style="font-size: 12px;">
                                                                            {{staticText?.vendor.vendor_res}}</p>
                                                                        <p class="m-0"
                                                                            style="font-size: 14px; font-weight: 500">
                                                                            {{term.response}}</p>
                                                                    </div>
                                                                    <div class="col-sm-3 mb-2">
                                                                        <p class="mb-0" style="font-size: 12px;">
                                                                            {{staticText?.vendor?.deg_coverage}}</p>
                                                                        <p class="m-0"
                                                                            style="font-size: 14px; font-weight: 500">
                                                                            {{term.coverage}}</p>
                                                                    </div>
                                                                    <div class="col-sm-9 mb-2">
                                                                        <p class="mb-0" style="font-size: 12px;">
                                                                            {{staticText?.vendor?.refs}}</p>
                                                                        <p class="m-0"
                                                                            style="font-size: 14px; font-weight: 500; word-break: break-all;">
                                                                            {{term.refs}}</p>
                                                                    </div>
                                                                </div>
                                                                <hr *ngIf="!user?.is_vendor" class="mt-2 mb-2">

                                                                <div class="row">

                                                                    <div
                                                                        class="col-md-12 pl-4 pr-0 field-adjust d-flex justify-content-between align-items-center">
                                                                        <div class="row w-100">
                                                                            <div class="col-md-5 align-items-center"
                                                                                *ngIf="user?.is_vendor">
                                                                                <mat-form-field
                                                                                    class="w-100 textarea-input"
                                                                                    appearance="outline">
                                                                                    <mat-label>{{staticText?.vendor.vendor_res}}</mat-label>
                                                                                    <input type="text" matInput
                                                                                        status=" basic"
                                                                                        [(ngModel)]="term.vendorResponse"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        name="vendorResponse">
                                                                                </mat-form-field>
                                                                            </div>
                                                                            <div class="col-md-3 d-flex align-items-center"
                                                                                *ngIf="user?.is_vendor">
                                                                                <mat-form-field
                                                                                    class="w-100 textarea-input "
                                                                                    appearance="outline">
                                                                                    <mat-label>{{staticText?.vendor?.deg_coverage}}</mat-label>
                                                                                    <mat-select
                                                                                        [(ngModel)]="term.coverage"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        name="coverage">
                                                                                        <mat-option>Select</mat-option>
                                                                                        <mat-option
                                                                                            value="Fully Addressed">Fully
                                                                                            Addressed</mat-option>
                                                                                        <mat-option
                                                                                            value="Partially Addressed">Partially
                                                                                            Addressed</mat-option>
                                                                                        <mat-option
                                                                                            value="Not Addressed">Not
                                                                                            Addressed</mat-option>
                                                                                    </mat-select>
                                                                                </mat-form-field>

                                                                            </div>
                                                                            <div class="col-md-4 d-flex align-items-center"
                                                                                *ngIf="user?.is_vendor">

                                                                                <mat-form-field
                                                                                    class="w-100 textarea-input"
                                                                                    appearance="outline">
                                                                                    <mat-label>{{staticText?.vendor?.refs}}</mat-label>
                                                                                    <input type="text" matInput
                                                                                        status=" basic"
                                                                                        [(ngModel)]="term.refs"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        name="refs"
                                                                                        [readonly]="!canEdit">
                                                                                </mat-form-field>

                                                                            </div>
                                                                            <div class="col-md-2 score-width p-0 d-flex align-items-center"
                                                                                *ngIf="user?.permissions?.indexOf('opportunity.add_opportunity') < 0 && !user?.is_vendor">
                                                                                <mat-form-field
                                                                                    class=" w-100 textarea-input  mat-size"
                                                                                    id="setPadding"
                                                                                    appearance="outline">
                                                                                    <mat-label>{{staticText?.vendor?.score}}</mat-label>
                                                                                    <input type="number" matInput
                                                                                        fullWidth status="basic"
                                                                                        class="input-style"
                                                                                        (blur)="checkValTerm(catIndex, termIndex, term.score)"
                                                                                        [(ngModel)]="term.score"
                                                                                        max="100"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        name="score"
                                                                                        [ngClass]="{'green-cl': term.score > 6 , 'orange': term.score <5 }">
                                                                                </mat-form-field>
                                                                            </div>
                                                                            <div class="col-md-10 d-flex align-items-center"
                                                                                *ngIf="user?.permissions?.indexOf('opportunity.add_opportunity') < 0 && !user?.is_vendor">
                                                                                <mat-form-field
                                                                                    class=" w-100 textarea-input  mat-size"
                                                                                    id="setPadding"
                                                                                    appearance="outline">
                                                                                    <mat-label>{{staticText?.toe_response?.notes}}</mat-label>
                                                                                    <input type="text"
                                                                                        cdkTextareaAutosize
                                                                                        cdkAutosizeMinRows="" class=""
                                                                                        matInput fullWidth
                                                                                        status="basic"
                                                                                        [(ngModel)]="term.notes"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        name="notes"
                                                                                        placeholder="Assessor Notes">
                                                                                </mat-form-field>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <div *ngIf="user?.permissions?.indexOf('opportunity.add_opportunity') > -1 && !user?.is_vendor"
                                                                    style="border: 1px solid #cfc2c2; border-radius: 7px;">
                                                                    <div style="background-color: #d5d0d0; border-radius: 5px;"
                                                                        class="mb-1 p-2">
                                                                        <div class="row">
                                                                            <div class="col-sm-3">
                                                                                <p class="m-0" style="font-size: 12px;">
                                                                                    Assessor Name </p>
                                                                            </div>
                                                                            <div class="col-sm-7">
                                                                                <p class="m-0" style="font-size: 12px;">
                                                                                    Assessor Notes </p>
                                                                            </div>
                                                                            <div class="col-sm-2">
                                                                                <p class="m-0 text-center"
                                                                                    style="font-size: 12px;">Score </p>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div *ngFor="let h of term.evals"
                                                                        style=" border-radius: 5px; border-bottom: 1px solid #ded2d2;"
                                                                        class="p-2">
                                                                        <div class="row">
                                                                            <div class="col-sm-3">
                                                                                <p class="m-0" style="font-size: 12px;">
                                                                                    {{h.created_by__first_name }}
                                                                                    {{h.created_by__last_name }}</p>
                                                                            </div>
                                                                            <div class="col-sm-7">
                                                                                <p class="m-0" style="font-size: 12px;">
                                                                                    {{h.notes }} </p>
                                                                            </div>
                                                                            <div class="col-sm-2">
                                                                                <p class="m-0 text-center"
                                                                                    style="font-size: 12px;">{{h.score
                                                                                    }} </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!user?.is_vendor ? !responseToeSubmitted : false">
                        <h6 class="my-4 text-center">Awaiting vendor response</h6>
                    </div>

                    <div class="d-flex justify-content-end mt-2 p-3 pt-2 footer-style"
                        *ngIf="(!user?.is_vendor ? responseToeSubmitted : user?.is_vendor && responseToeSubmitted ? false : true) && user?.permissions?.indexOf('opportunity.add_opportunity') < 0">
                        <div>
                            <button type="button" mat-button 
                                (click)="clickPrevious()">{{staticText?.buttons.cancel_btn}}
                            </button>
                        </div>
                        <div *ngIf="!disableFields">
                            <button type="button" mat-stroked-button mat-button class="mx-1" style="width: auto;"
                                (click)="updateTermsEvaluationVendorComment(false)"
                                *ngIf="user?.is_vendor">{{staticText?.buttons.save_draft}}
                            </button>
                            <button type="button" *ngIf="user?.is_vendor" mat-button mat-flat-button class="bg-primary text-white mx-1"
                                (click)="updateTermsEvaluationVendorComment(true)">{{staticText?.buttons.submit_draft}}</button>

                            <button type="button" mat-button mat-flat-button class="bg-primary text-white mx-1"
                                (click)="updateTermsEvaluationVendorComment()"
                                *ngIf="!user?.is_vendor">{{staticText?.buttons.save_btn}}
                            </button>
                        </div>
                    </div>

                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>


<ng-template #delete>
    <div class="modal-dialog custom-width m-0">
        <div class="modal-content">
            <app-modal-header-common [title]="staticText?.modal_popup?.confirmation_title"> </app-modal-header-common>

            <div class="modal-body">
                <p class=" fs-5 py-3 text-center primary m-0 fw-bold">{{staticText?.modal_popup?.del_msg}}</p>
            </div>
            <div class="modal-footer border-0">
                <div class="row">
                    <div class="col-md-6">
                        <button mat-button mat-dialog-close
                            (click)="closeDialog()">{{staticText?.modal_popup?.no_btn}}</button>
                    </div>
                    <div class="col-md-6">
                        <button mat-button [disabled]="disableFields" mat-dialog-close (click)="deleteDialog()"
                            class="bg-primary text-white">{{staticText?.modal_popup?.yes_btn}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>